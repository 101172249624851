.nice-select {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: #fff;
  border-radius: 5px;
  border: solid 1px #e8e8e8;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: block;
  float: left;
  font-family: inherit;
  font-size: 14px;
  font-weight: normal;
  height: 38px;
  line-height: 36px;
  outline: none;
  padding-left: 18px;
  padding-right: 30px;
  position: relative;
  text-align: left !important;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  width: auto;
}
.nice-select:hover {
  /* border-color: #dbdbdb; */
  border-color: #aaa;
}
.nice-select:active,
.nice-select.open,
.nice-select:focus {
  border-color: #777;
  /* border: 2px solid #777; */
}
.nice-select:after {
  /* arrow icon */
  border-bottom: 2px solid #999;
  border-right: 2px solid #999;
  content: "";
  display: block;
  height: 5px;
  margin-top: -4px;
  pointer-events: none;
  position: absolute;
  right: 12px;
  top: 50%;
  -webkit-transform-origin: 66% 66%;
  transform-origin: 66% 66%;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  width: 5px;
}
.nice-select.open:after {
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
.nice-select.open .nice-select-dropdown {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: scale(1) translateY(0);
  transform: scale(1) translateY(0);
}
.nice-select.disabled {
  border-color: #ededed;
  color: #999;
  pointer-events: none;
}
.nice-select.disabled:after {
  border-color: #cccccc;
}
.nice-select.wide {
  width: 100%;
}
.nice-select.wide .nice-select-dropdown {
  left: 0 !important;
  right: 0 !important;
}
.nice-select.right {
  float: right;
}
.nice-select.right .nice-select-dropdown {
  left: auto;
  right: 0;
}
.nice-select.small {
  font-size: 12px;
  height: 36px;
  line-height: 34px;
}
.nice-select.small:after {
  height: 4px;
  width: 4px;
}
.nice-select.small .option {
  line-height: 34px;
  min-height: 34px;
}
.nice-select .nice-select-dropdown {
  margin-top: 4px;
  background-color: #fff;
  border-radius: 5px;
  -webkit-box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
  box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
  pointer-events: none;
  position: absolute;
  top: 100%;
  left: 0;
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -webkit-transform: scale(0.75) translateY(-19px);
  transform: scale(0.75) translateY(-19px);
  -webkit-transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25),
    opacity 0.15s ease-out;
  transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
  z-index: 999;
  opacity: 0;
}
.nice-select .list {
  border-radius: 5px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  padding: 0;
  max-height: 210px;
  overflow-y: auto;
}
.nice-select .list:hover .option:not(:hover) {
  background-color: transparent !important;
}
.nice-select .option {
  cursor: pointer;
  font-weight: 400;
  line-height: 40px;
  list-style: none;
  outline: none;
  padding-left: 18px;
  padding-right: 29px;
  text-align: left;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.nice-select .option:hover,
.nice-select .option.focus,
.nice-select .option.selected.focus {
  background-color: #f6f6f6;
}
.nice-select .option.selected {
  font-weight: bold;
}
.nice-select .option.disabled {
  background-color: transparent;
  color: #999;
  cursor: default;
}

.no-csspointerevents .nice-select .nice-select-dropdown {
  display: none;
}

.no-csspointerevents .nice-select.open .nice-select-dropdown {
  display: block;
}

.nice-select .list::-webkit-scrollbar {
  width: 0;
}

.nice-select .has-multiple {
  white-space: inherit;
  height: auto;
  padding: 7px 12px;
  min-height: 36px;
  line-height: 22px;
}
.nice-select .has-multiple span.current {
  border: 1px solid #ccc;
  background: #eee;
  padding: 0 10px;
  border-radius: 3px;
  display: inline-block;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 3px;
  margin-right: 3px;
}
.nice-select .has-multiple .multiple-options {
  display: block;
  line-height: 24px;
  padding: 0;
}

.nice-select .nice-select-search-box {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  padding: 5px;
  pointer-events: none;
  border-radius: 5px 5px 0 0;
}

.nice-select .nice-select-search {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  color: #444;
  display: inline-block;
  vertical-align: middle;
  padding: 7px 12px;
  margin: 0 10px 0 0;
  width: 100%;
  min-height: 36px;
  line-height: 22px;
  height: auto;
  outline: 0 !important;
  font-size: 14px;
}

.height-50 {
  height: 50px;
}

.select__input {
  height: auto !important;
}
